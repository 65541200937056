import { FC } from 'react';
import { ApiGroup } from '../../types';
import './groups.scss';
import { ServersView } from './servers';

/* -------------------------------------------------------------------------------- *\
|                               GroupsProps
\* -------------------------------------------------------------------------------- */
interface GroupsProps
{
	list: ApiGroup[],
	fullview: boolean,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               GroupsView
\* -------------------------------------------------------------------------------- */
export const GroupsView : FC<GroupsProps> = ({list, fullview}) =>
{
    return (
        <div className='groups'>
            {
                list.map((apigroup, index) =>
                {
                    return (
                        <div className='groups__group' key={'group_' + index}>
                            <div className='groups__group__cont'>
                                <div className='groups__group__title'>{apigroup.name}</div>
                                <ServersView list={apigroup.servers} fullview={fullview} />
                            </div>
                        </div>
                    ) 
                })
            }
        </div>
    );
}
