import { FC, Fragment, ReactNode } from 'react';
import { ApiRaceStats } from '../../types';
import './infostats.scss';

/* -------------------------------------------------------------------------------- *\
|                               StatsRace
\* -------------------------------------------------------------------------------- */
class StatsRace
{
    public context: string = "";
    public raceno: number = 0;
    public infolines: string[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               InfosStatsView
\* -------------------------------------------------------------------------------- */
export const InfosStatsView : FC<{children?: ReactNode, sic: string, races: ApiRaceStats[]}> = ({sic = '', races = []}) =>
{
    var newraces: StatsRace[] = [];
    for(const rs of races)
    {
        const raceno: number = Number(rs.context.substring(11));
        
        var sr: StatsRace = new StatsRace();

        if (rs.infolines.length > 0)
        {
            sr.raceno = raceno;
            sr.context = rs.context;
            sr.infolines = rs.infolines ? rs.infolines : [];
            newraces.push(sr);
        }
    }
    newraces.sort((a, b) => a.context.localeCompare(b.context));

    const header: string[] = ['num','infos'];
    return (
        <div className='infostats'>
            <div className='infostats__race'>
                <div className='infostats__title'>{sic}</div>
                <table className='infostats__table'>
                    <tbody>
                    <tr className='infostats__table__row infostats__table__row__header'>
                    {
                        header.map((h, ih) =>
                        {
                            return (
                                <td className='infostats__table__item' key={'header' + ih}>{h}</td>
                            )
                        })
                    }
                    </tr>
                    {
                        newraces.map((race, ir) =>
                        {
                            return (
                                <Fragment key={'race' + ir}>
                                {
                                    race.infolines.map((line, il) =>
                                    {
                                        return (
                                            <tr className={'infostats__table__row' + (il === 0 ? ' infostats__table__row__top' : '')} key={'line' + il}>
                                                <td className='infostats__table__item' key={'line' + il + "_0"}>{il === 0 ? String(race.raceno) : ''}</td>
                                                <td className='infostats__table__item infostats__table__item__left' colSpan={6} key={'line' + il + "_1"}>{line}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </Fragment>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
