import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ApiServer, ApiState } from '../../types';
import './server.scss';
import { StatesView } from './states';

/* -------------------------------------------------------------------------------- *\
|                               ServerProps
\* -------------------------------------------------------------------------------- */
interface ServerProps
{
	apiserver: ApiServer,
	fullview: boolean,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               useResize
\* -------------------------------------------------------------------------------- */
const useResize = (myRef: any) =>
{
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const handleResize = useCallback(() =>
    {
        setWidth(myRef.current.offsetWidth)
        setHeight(myRef.current.offsetHeight)
    }, [myRef])

    useEffect(() =>
    {
        window.addEventListener('load', handleResize)
        window.addEventListener('resize', handleResize)

        if (width === 0 && myRef.current.offsetWidth) setWidth(myRef.current.offsetWidth)
        if (height === 0 && myRef.current.offsetHeight) setHeight(myRef.current.offsetHeight)
        return () =>
        {
            window.removeEventListener('load', handleResize)
            window.removeEventListener('resize', handleResize)
        }
    }, [myRef, handleResize, width, height])

    return { width, height }
}
  /* -------------------------------------------------------------------------------- *\
|                               ServerView
\* -------------------------------------------------------------------------------- */
export const ServerView : FC<ServerProps> = ({apiserver, fullview}) =>
{
    const reftitle = useRef(null);
    const refname = useRef(null);
    const reflink = useRef(null);
    const sizetitle = useResize(reftitle);
    const sizename = useResize(refname);
    const sizelink = useResize(reflink);

    var state: boolean = true;
    const uptime: ApiState | undefined = apiserver.states.find(n => n.probe === 'uptime');
    if (uptime && uptime.value)
    {
        for(const s of apiserver.states)
        {
            if (s.color === 'alert' || s.color === 'alarm')
            {
                state = false;
                break;
            }
        }
    }
    else
    {
        state = false;
    }
    const linkhpv2: ApiState | undefined = apiserver.states.find(n => n.probe === 'linkhpv2');
    const linktext: string = linkhpv2 ? linkhpv2.value : "";
    const linkcolor: string = linkhpv2 && linkhpv2.color ? linkhpv2.color : "alarm";

    const [scalex, setScaleX] = useState<number>(0);
    const [display, setDisplay] = useState<string>("inline");
    useEffect(() =>
    {
        const fullwidth: number = sizetitle.width - 10;
        if (sizename.width > 0 && sizename.width + sizelink.width > fullwidth)
        {
            const nw: number = fullwidth - sizelink.width;
            if (nw > 0)
            {
                setScaleX(nw / sizename.width);
                setDisplay('inline-block');
            }
        }
    }, [scalex, display, sizename, sizelink, sizetitle]);
    return (
        <div className='server'>
            <div className={'server__title ' + (state ? 'server__title__good' : '')} ref={reftitle}>
                <div className='server__title__linkhpv2' ref={reflink} style={{'background': `var(--state-${linkcolor})`}}>{linktext}</div>
                <div className='server__title__name' ref={refname} style={{display: `${display}`, transform: `scaleX(${scalex})`}}>{apiserver.name}</div>
                {
                    fullview &&
                    (
                        <div className='server__title__ip'>{apiserver.ip}</div>
                    )
                }
            </div>
            <div className='server__cont'>
                <StatesView list={apiserver.states} fullview={fullview}/>
            </div>
        </div>
    ) 
}
