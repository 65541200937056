import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import './app.scss';

/* -------------------------------------------------------------------------------- *\
|                               useKeys
\* -------------------------------------------------------------------------------- */
export const useKeys = (callbackdown: any, callbackup: any, keys: string[]) =>
{
    const callbackdownRef = useRef(callbackdown);
    const callbackupRef = useRef(callbackup);
    useLayoutEffect(() => { callbackdownRef.current = callbackdown; callbackupRef.current = callbackup; });

    const handleKeyDown = useCallback((event: any) =>
    {
        if (keys.includes(event.key))
        {
            callbackdownRef.current(event);
        }
    }, [keys]);
    const handleKeyUp = useCallback((event: any) =>
    {
        if (keys.includes(event.key))
        {
            callbackupRef.current(event);
        }
    }, [keys]);

    useEffect(() =>
    {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () =>
        {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyDown, handleKeyUp, keys]);
}
