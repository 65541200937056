import { FC, useState } from 'react';
import { Race } from '../../types';
import './rollingtext.scss';

/* -------------------------------------------------------------------------------- *\
|                               RollingTextProps
\* -------------------------------------------------------------------------------- */
interface RollingTextProps
{
	race: Race,
	timer: number,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               ProgramView
\* -------------------------------------------------------------------------------- */
export const RollingTextView : FC<RollingTextProps> = ({race, timer}) =>
{
    const [line1, setLine1] = useState<string>('');
    const [line2, setLine2] = useState<string>('');
    const [line, setLine] = useState<number>(0);
    const [lasttimer, setLastTimer] = useState<number>(0);
    const [effect, setEffect] = useState<boolean>(false);

    var texts: string[] = [];

    texts.push(race.Distance + "m" + (race.Runners ? " runners=" + race.Runners : "") + (race.Path ? " code=" + race.Path : "") + " " + (race.Nonrunners && race.Nonrunners.length > 0 ? "NR:" + race.Nonrunners.join(',') : ""));
    texts.push(race.Name ? race.Name : "no name");
    texts.push("state: " + (race.State ? race.State : "empty"));

    if (lasttimer !== timer)
    {
        setLastTimer(timer);
        if (texts.length > 1)
        {
            if (!effect)
            {
                setEffect(true);
            }
            else
            {
                setLine1(line2);
                setEffect(false);
                
                var l = line;
                l = (l + 1) % texts.length;
                setLine(l);
                setLine2(texts[l]);
            }
        }
        else
        {
            setEffect(false);
            setLine1(texts.length ? texts[0] : '');
            setLine2('');
        }
    }
 
    const classeffect = effect ? 'racetexts__effect' : 'racetexts__noeffect'; 
    return (
        <div className='racetexts'>
            <div className='racetexts__cont'>
                <div className={`racetexts__line1 ${classeffect}`}>{line1}</div>
                <div className={`racetexts__line2 ${classeffect}`}>{line2}</div>
            </div>
        </div>
    );
}
