import { FC, ReactNode } from 'react';
import { ApiRaceStats } from '../../types';
import './homestats.scss';
import { MeetingStatsView } from './meetingstats';

/* -------------------------------------------------------------------------------- *\
|                               StatsMeeting
\* -------------------------------------------------------------------------------- */
class StatsMeeting
{
    public date: string = "";
    public sic: string = "";
    public races: ApiRaceStats[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(date: string, sic: string)
    {
        this.date = date;
        this.sic = sic;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               HomestatsView
\* -------------------------------------------------------------------------------- */
export const HomestatsView : FC<{children?: ReactNode, races: ApiRaceStats[]}> = ({races = []}) =>
{
    var meetings: StatsMeeting[] = [];
    for(const rs of races)
    {
        const date: string = rs.context.substring(0, 8);
        const sic: string = rs.context.substring(8, 11);
        // const raceno: number = Number(rs.context.substring(11));

        var sm: StatsMeeting | undefined = meetings.find(m => m.date === date && m.sic === sic);
        if (!sm)
        {
            sm = new StatsMeeting(date, sic);
            meetings.push(sm);
        }
        
        sm.races.push(rs);
    }
    meetings.sort((a, b) => 
    {
        const r = a.date.localeCompare(b.date);
        if (r) return r;
        return a.sic.localeCompare(b.sic);
    });
    meetings = meetings.slice(-4);
    for(const sm of meetings)
    {
        sm.races.sort((a, b) => a.context.localeCompare(b.context));
    }
    return (
        <div className='homestats'>
        {
            meetings.map((meeting, index) =>
            {
                return (
                    <div className='homestats__meeting' key={'meeting_' + index}>
                        <MeetingStatsView showdate={true} date={meeting.date} races={meeting.races} sic={meeting.sic} operators={[]} />
                    </div>
                )
            })
        }
        </div>
    );
}
