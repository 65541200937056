import { FC, ReactNode, useEffect, useState } from 'react';
import packageJson from '../../package.json';
import { api_get_global, api_get_lives, api_get_program, api_get_racestats } from '../api.service';
import { Footer } from '../components/footer';
import { GroupsView } from '../components/group/groups';
import { Header } from '../components/header';
import { NoServer } from '../components/noserver';
import './home.scss';

import { ProgramView } from '../components/program/program';
import { HomestatsView } from '../components/racestats/homestats';
import { useKeys } from '../keys';
import { ApiAnswerRaceStats, ApiGlobal, ApiGroup, ApiLives, ApiMeetings, ApiRaceStats, Meeting, RacingInfos } from '../types';

var g_updating_groups: boolean = false;
var g_updating_lives: boolean = false;
var g_updating_homeracestats: boolean = false;
var g_updating_program: boolean = false;
/* -------------------------------------------------------------------------------- *\
|                               HomePage
\* -------------------------------------------------------------------------------- */
export const HomePage: FC<{ children?: ReactNode }> = ({ children }) =>
{
    const [fullview, setFullView] = useState(false);
    const onKeyDown = () => setFullView(!fullview);
    const onKeyUp = () => {};

    useKeys(onKeyDown, onKeyUp, ['Escape']);

    const [groups, setGroups] = useState<ApiGroup[]>([]);
    const [lives, setLives] = useState<RacingInfos[]>([]);
    const [racestats, setRacestats] = useState<ApiRaceStats[]>([]);

    /* -------------------------------------------------------------------------------- *\
    |                               updategroups
    \* -------------------------------------------------------------------------------- */
    function updategroups()
    {
        if (g_updating_groups) return;
        g_updating_groups = true;
        api_get_global().then((input: ApiGlobal) =>
        {
            setGroups(input.groups);
        })
        .catch(() => setGroups([]))
        .finally(() => g_updating_groups = false);
    }
    useEffect(() =>
    {
        updategroups();
        const interval = setInterval(() =>
        {
            updategroups();
        }, 2000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    /* -------------------------------------------------------------------------------- *\
    |                               updatelives
    \* -------------------------------------------------------------------------------- */
    function updatelives()
    {
        if (g_updating_lives) return;
        g_updating_lives = true;
        api_get_lives().then((input: ApiLives) =>
        {
            setLives(input.lives);
        })
        .catch(() => setLives([]))
        .finally(() => g_updating_lives = false);
    }
    useEffect(() =>
    {
        updatelives();
        const interval = setInterval(() =>
        {
            updatelives();
        }, 500);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    /* -------------------------------------------------------------------------------- *\
    |                               updateracestats
    \* -------------------------------------------------------------------------------- */
    function updateracestats()
    {
        if (g_updating_homeracestats) return;
        g_updating_homeracestats = true;
        const beforenow: Date = new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000);
        const datestart: string = beforenow.getFullYear().toString() + beforenow.getMonth().toString().padStart(2, '0') + beforenow.getDate().toString().padStart(2, '0');
        api_get_racestats(datestart).then((datas: ApiAnswerRaceStats) =>
        {
            setRacestats(datas.races);
        })
        .catch(() => setRacestats([]))
        .finally(() => g_updating_homeracestats = false);
    }
    useEffect(() =>
    {
        updateracestats();
        const interval = setInterval(() =>
        {
            updateracestats();
        }, 30000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const [program, setProgram] = useState<Meeting[]>([]);
    const [date, setDate] = useState<string>("");
    /* -------------------------------------------------------------------------------- *\
    |                               updateprogram
    \* -------------------------------------------------------------------------------- */
    function updateprogram()
    {
        if (g_updating_program) return;
        g_updating_program = true;
        api_get_program().then((input: ApiMeetings) =>
        {
            if (!input) return;

            setDate(input.date);
            setProgram(input.meetings);
        })
        .catch(() => { setProgram([]); })
        .finally(() => g_updating_program = false);
    }
    useEffect(() =>
    {
        updateprogram();
        const interval = setInterval(() =>
        {
            updateprogram();
        }, 5000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    return (
        <div className="home">
            <Header title="Supervision"  />
            <div className='home__button' onClick={() => setFullView(!fullview)}>full</div>
            <div className='home__maincont'>
                <div className='home__maincont__central'>
                    <div className='home__maincont__central__servers'>
                        { groups && groups.length > 0 ? (<GroupsView list={groups} fullview={fullview} />) : (<NoServer/>)}
                    </div>
                    <div className='home__maincont__central__racestats'>
                        { racestats && racestats.length > 0 && (<HomestatsView races={racestats} />) }
                        { (!racestats || racestats.length === 0) && <div></div> }
                    </div>
                </div>
                <div className='home__maincont__program'>
                    <ProgramView list={program} lives={lives} date={date} />
                </div>
            </div>
            
            <Footer text={'supervision @mclloyd 2024 - version ' + packageJson.version} />
        </div>
    );
}
