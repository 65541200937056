import { FC } from 'react';
import { ApiState } from '../../types';
import './gauge.scss';

/* -------------------------------------------------------------------------------- *\
|                               GaugeProps
\* -------------------------------------------------------------------------------- */
interface GaugeProps
{
	state: ApiState,
	fullview: boolean,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               GaugeView
\* -------------------------------------------------------------------------------- */
export const GaugeView : FC<GaugeProps> = ({state, fullview}) =>
{
    var v:number = state.numeric ? state.numeric : 0;
    if (v < 0) v = 0;
    if (v > 100) v = 100;
    var color: string = 'good';
    if (state.color) color = state.color;
    return (
        <div>
            {
                fullview &&
                (
                    <div className='gauge__cont'>
                        <div className='gauge__gauge' style={{'top': (100 - v) + "%", 'background': `var(--state-${color})`}}></div>
                        <div className='gauge__title'>{state.name}</div>
                        <div className='gauge__value'>{state.value}</div>
                        { (state.valuecurrent) ? (<div className='gauge__free'>free {state.valuecurrent}</div>) : "" }
                    </div>
                )
            }
        </div>
    );
}
