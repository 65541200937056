import axios from "axios";
import { ApiAnswerRaceStats, ApiGlobal, ApiLives, ApiMeetings, ApiOperator, ApiRacingRace, ApiRacingServer, ApiTrack } from './types';

/* -------------------------------------------------------------------------------- *\
|                               geturl
\* -------------------------------------------------------------------------------- */
function geturl() : string
{
    var url = 'https://supervision-api.mclloyd.com';
    if (process.env.REACT_APP__API_URL)
    {
        url = process.env.REACT_APP__API_URL
    }
    return url;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_global
\* -------------------------------------------------------------------------------- */
export const api_get_global = (): Promise<ApiGlobal> =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.get(url + '/global'))
        .then(response => response.data)
        .then(data => ({ groups: data.groups, lives: data.lives }))
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_lives
\* -------------------------------------------------------------------------------- */
export const api_get_lives = (): Promise<ApiLives> =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.get(url + '/getlives'))
        .then(response => response.data)
        .then(data => ({ lives: data.lives }))
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_program
\* -------------------------------------------------------------------------------- */
export const api_get_program = (): Promise<ApiMeetings> =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.get(url + '/getprogram'))
        .then(response => response.data)
        .then(data => ({ date: data.date, version: data.version, meetings: data.meetings ? data.meetings : [] }))
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_getparams_racestats
\* -------------------------------------------------------------------------------- */
export const api_getparams_racestats = (full: boolean, datestart: string | undefined, dateend: string | undefined = undefined, track: number = 0, server: number = 0, operator: number = 0): string =>
{
    const param: string[] = [];
    
    if (datestart) param.push('datestart=' + datestart.replace(/-/g, ''));
    if (dateend) param.push('dateend=' + dateend.replace(/-/g, ''));
    if (full && !datestart) param.push('datestart=null');
    if (full && !dateend) param.push('dateend=null');
    if (track) param.push('track=' + track);
    if (server) param.push('server=' + server);
    if (operator) param.push('operator=' + operator);

    return param.length === 0 ? '' : ('?' + param.join('&'));
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_racestats
\* -------------------------------------------------------------------------------- */
export const api_get_racestats = (datestart: string | undefined, dateend: string | undefined = undefined, track: number = 0, server: number = 0, operator: number = 0): Promise<ApiAnswerRaceStats> =>
{
    var url = geturl();
    const params: string = api_getparams_racestats(false, datestart, dateend, track, server, operator);
    return Promise.resolve()
        .then(() => axios.get(url + '/getracestats' + params))
        .then(response => response.data)
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_raceingraces
\* -------------------------------------------------------------------------------- */
export const api_get_raceingraces = (count: number = 0): Promise<ApiRacingRace[]> =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.get(url + '/getracingraces'))
        .then(response => response.data)
        .then(data => data.races ? data.races : [] )
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_operators
\* -------------------------------------------------------------------------------- */
export const api_get_operators = (): Promise<ApiOperator[]> =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.get(url + '/getoperators'))
        .then(response => response.data)
        .then(data => data ? data : [] )
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_racingservers
\* -------------------------------------------------------------------------------- */
export const api_get_racingservers = (): Promise<ApiRacingServer[]> =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.get(url + '/getservers'))
        .then(response => response.data)
        .then(data => data ? data : [] )
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_get_tracks
\* -------------------------------------------------------------------------------- */
export const api_get_tracks = (): Promise<ApiTrack[]> =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.get(url + '/gettracks'))
        .then(response => response.data)
        .then(data => data ? data : [] )
        ;
}
/* -------------------------------------------------------------------------------- *\
|                               api_set_meetingoperator
\* -------------------------------------------------------------------------------- */
export const api_set_meetingoperator = (date: string, sic: string, operator: number) =>
{
    var url = geturl();
    return Promise.resolve()
        .then(() => axios.post(url + '/setmeetingoperator?date=' + date + '&sic=' + sic + '&operator=' + operator))
        .then(response => response.data)
        ;
}
