import { FC, ReactNode, useEffect, useState } from 'react';
import packageJson from '../../package.json';
import { api_get_raceingraces } from '../api.service';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import './racingraces.scss';

import { RacinRaceView } from '../components/racingraces/racingrace';
import { ApiRacingRace } from '../types';

var g_updating_races: boolean = false;
/* -------------------------------------------------------------------------------- *\
|                               MeetingStats
\* -------------------------------------------------------------------------------- */
class MeetingStats
{
    public sic: string = "";
    public races: ApiRacingRace[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(sic: string)
    {
        this.sic = sic;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               DayStats
\* -------------------------------------------------------------------------------- */
class DayStats
{
    public date: string = "";
    public fulldate: string = "";
    public meetings: MeetingStats[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(date: string)
    {
        this.date = date;
        this.fulldate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
    }
}
/* -------------------------------------------------------------------------------- *\
|                               RacingRacesPage
\* -------------------------------------------------------------------------------- */
export const RacingRacesPage: FC<{ children?: ReactNode }> = ({ children }) =>
{
    const [races, setRaces] = useState<ApiRacingRace[]>([]);
    const [days, setDays] = useState<DayStats[]>([]);

    /* -------------------------------------------------------------------------------- *\
    |                               updateraces
    \* -------------------------------------------------------------------------------- */
    function updateraces()
    {
        if (g_updating_races) return;
        g_updating_races = true;
        api_get_raceingraces().then((races: ApiRacingRace[]) =>
        {
            setRaces(races);
        })
        .catch(() => setRaces([]))
        .finally(() => g_updating_races = false);
    }
    useEffect(() =>
    {
        updateraces();
        const interval = setInterval(() =>
        {
            updateraces();
        }, 30000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    useEffect(() =>
    {
        var days: DayStats[] = [];
        for(const apirace of races)
        {
            const date: string = apirace.context.substring(0, 8);
            const sic: string = apirace.context.substring(8, 11);
            
            var df = days.find(n => n.date === date);
            var day: DayStats = df ? df : new DayStats(date);

            var mf = day.meetings.find(n => n.sic === sic);
            var meeting: MeetingStats = mf ? mf : new MeetingStats(sic);

            meeting.races.push(apirace);
            if (!mf) day.meetings.push(meeting);
            if (!df) days.push(day);
        }
        days.sort(function (a, b) { return -a.date.localeCompare(b.date); } );
        for(const day of days)
        {
            day.meetings.sort(function (a, b) { return a.sic.localeCompare(b.sic); } );

            for(const meeting of day.meetings)
            {
                meeting.races.sort(function (a, b) { return a.context.localeCompare(b.context); } );
            }
        }
        setDays(days);
    }, [races]);

    return (
        <div>
            <Header title="Supervision" />
            <div className="racingraces">
                <div className='racingraces__maincont'>
                {
                    days.map((day, index) =>
                    {
                        return (
                            <div className='racingraces__day' key={'day' + index}>
                                <div className='racingraces__day__title'>{day.fulldate}</div>
                                <div className='racingraces__day__details'>
                                {
                                    day.meetings.map((meeting, index) =>
                                    {
                                        return (
                                            <div className='racingraces__day__details__meeting' key={'details' + index}>
                                                <RacinRaceView sic={meeting.sic} races={meeting.races} />
                                            </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    )
                })
            }
            </div>
            
            <Footer text={'supervision @mclloyd 2024 - version ' + packageJson.version} />
        </div>
        </div>
    );
}
