import { FC, ReactNode } from 'react';
import { ApiRacingRace } from '../../types';
import './racingrace.scss';

/* -------------------------------------------------------------------------------- *\
|                               RacinRaceView
\* -------------------------------------------------------------------------------- */
export const RacinRaceView : FC<{children?: ReactNode, sic: string, races: ApiRacingRace[]}> = ({sic, races = []}) =>
{
    return (
        <div className='racingrace'>
            <div className='racingrace__title'>{sic}</div>
            <table className='racingrace__table'>
                <tbody>
                    <tr className='racingrace__table__row__header'>
                        <td className='racingrace__table__item'>Race</td>
                        <td className='racingrace__table__item'>Racing</td>
                        <td className='racingrace__table__item racingrace__table__item__flag'>full</td>
                        <td className='racingrace__table__item racingrace__table__item__flag'>sectionnal</td>
                        <td className='racingrace__table__item'>Log</td>
                    </tr>
                    {
                        races.map((race, ir) =>
                        {
                            const number = Number(race.context.substring(11));
                            return (
                                <tr className='racingrace__table__row' key={'race' + ir}>
                                    <td className='racingrace__table__item'>Race {number}</td>
                                    <td className='racingrace__table__item'>{race.server}</td>
                                    <td className='racingrace__table__item' style={{'background': `var(--state-${race.mecfull ? "good" : "alarm"})`}}>{race.mecfull ? "true" : "false"}</td>
                                    <td className='racingrace__table__item' style={{'background': `var(--state-${race.mecsectional ? "good" : "alarm"})`}}>{race.mecsectional ? "true" : "false"}</td>
                                    <td className='racingrace__table__item'><a  rel="noreferrer" href={race.httpracing + "/logs/mec"} target='_blank'>{race.httpracing + "/logs/mec"}</a></td>
                                </tr>)
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}
