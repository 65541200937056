import { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { api_get_program } from './api.service';
import './app.scss';
import { HomePage } from './pages/home';
import { RacingRacesPage } from './pages/racingraces';
import { StatsPage } from './pages/stats';
import { ApiMeetings, Meeting } from './types';

var g_updating_program: boolean = false;

export const ProgramContext = createContext<Meeting[]>([]);
export const useProgramContext = () =>
{
    return useContext(ProgramContext);
};
/* -------------------------------------------------------------------------------- *\
|                               App
\* -------------------------------------------------------------------------------- */
export default function App ()
{
    const [version, setVersion] = useState<number>(0);

    /* -------------------------------------------------------------------------------- *\
    |                               updateprogram
    \* -------------------------------------------------------------------------------- */
    function updateprogram()
    {
        if (g_updating_program) return;
        g_updating_program = true;
        api_get_program().then((input: ApiMeetings) =>
        {
            if (!input) return;

            if (version > 0 && version !== input.version)
            {
                window.location.reload();
            }
            setVersion(input.version);
        })
        .catch(() => { })
        .finally(() => g_updating_program = false);
    }
    useEffect(() =>
    {
        updateprogram();
        const interval = setInterval(() =>
        {
            updateprogram();
        }, 5000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="*" element={<HomePage />} />
                <Route index element={<HomePage />} />
                <Route path="/stats" element={<StatsPage />} />
                <Route path="/races" element={<RacingRacesPage />} />
            </Routes>
        </BrowserRouter>
    );
}
