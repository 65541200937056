import { FC, ReactNode, useEffect, useState } from 'react';
import { ApiRaceStats } from '../../types';
import './meetingstats.scss';
import { DefaultOptionType } from 'antd/es/cascader';
import { Select } from 'antd';
import { api_set_meetingoperator } from '../../api.service';

/* -------------------------------------------------------------------------------- *\
|                               StatsElements
\* -------------------------------------------------------------------------------- */
class StatsElements
{
    public value: string = "";
    public state: string = "neutral";

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(value: string, state: string = "neutral")
    {
        this.value = value;
        this.state = state;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               StatsRace
\* -------------------------------------------------------------------------------- */
class StatsRace
{
    public context: string = "";
    public raceno: number = 0;
    public elements: StatsElements[] = [];
    public errortext: string = "";
    public infolines: string[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               MeetingStatsView
\* -------------------------------------------------------------------------------- */
export const MeetingStatsView : FC<{children?: ReactNode, date: string, sic: string, races: ApiRaceStats[], operators: DefaultOptionType[], showdate: boolean}> = ({date = '', sic = '', races = [], operators, showdate}) =>
{
    var newraces: StatsRace[] = [];
    for(const rs of races)
    {
        const raceno: number = Number(rs.context.substring(11));
        
        var sr: StatsRace = new StatsRace();
        sr.raceno = raceno;
        sr.context = rs.context;
        sr.infolines = rs.infolines ? rs.infolines : [];
        sr.errortext = rs.errortext;
        sr.elements.push(new StatsElements(String(raceno)));
        sr.elements.push(new StatsElements(String(rs.runnercount)));
        sr.elements.push(new StatsElements(String(Math.floor(10 * rs.percent10hz) / 10), rs.percent10hz === 100 ? "neutral" : (rs.percent10hz < 95 ? "alarm" : "alert")));
        sr.elements.push(new StatsElements(String(Math.floor(10 * rs.percentPosC) / 10), rs.percentPosC === 100 ? "neutral" : (rs.percentPosC < 95 ? "alarm" : "alert")));
        sr.elements.push(new StatsElements(String(Math.floor(rs.latency / 10) / 100)));
        sr.elements.push(new StatsElements(String(rs.invalidcount), rs.invalidcount > 0 ? "alarm" : "neutral"));
        sr.elements.push(new StatsElements(String(Math.floor(rs.difftime / 10) / 100)));
        sr.elements.push(new StatsElements(String(rs.valid), rs.valid ? "good" : "alarm"));
        sr.elements.push(new StatsElements(String(rs.competitorsmec.length > 0), rs.competitorsmec.length > 0 ? "good" : "alarm"));
        newraces.push(sr);
    }
    newraces.sort((a, b) => a.context.localeCompare(b.context));

    const [operator, setOperator] = useState<number>(0);
    useEffect(() =>
    {
        setOperator(races.length === 0 ? 0 : races[0].operator);
    }, [races]);

    /* -------------------------------------------------------------------------------- *\
    |                               changeOperator
    \* -------------------------------------------------------------------------------- */
    function changeOperator(operator: number)
    {
        setOperator(operator);
        api_set_meetingoperator(date, sic, operator);
    }

    const header: string[] = ['num','tracked', '% 10hz', '% PosC', 'latency', '# invalid', 'difftime', 'valid', 'mec'];
    return (
        <div className='meetingstats'>
            <div className='meetingstats__title'>
                <div className='meetingstats__title__element'></div>
                <div className='meetingstats__title__element'>{(showdate && date ? date + ' ' : '') + ' ' + sic}</div>
                <div className='meetingstats__title__element'>
                {
                    operators.length > 0 &&
                    <Select className='meetingstats__title__element__select'
                        value={operator}
                        options={operators}
                        onChange={operator => changeOperator(operator)}
                        size='small'
                    />
                }
                </div>
            </div>
            <table className='meetingstats__table'>
                <tbody>
                <tr className='meetingstats__table__row meetingstats__table__row__header'>
                {
                    header.map((h, ih) =>
                    {
                        return (
                            <td className='meetingstats__table__item' key={'header' + ih}>{h}</td>
                        )
                    })
                }
                </tr>
                {
                    newraces.map((race, ir) =>
                    {
                        return (
                            <tr className='meetingstats__table__row' key={'race' + ir}>
                            {
                                !race.errortext &&
                                (
                                    race.elements.map((e, ie) =>
                                    {
                                        return (
                                            <td className='meetingstats__table__item' key={'race' + ir + "_" + ie} style={{'background': `var(--state-${e.state})`}}>{e.value}</td>
                                        )
                                    })
                                )
                            }
                            {
                                race.errortext &&
                                (
                                    <td className='meetingstats__table__item' key={'race' + ir + "_" + 0} style={{'background': `var(--state-${race.elements[0].state})`}}>{race.elements[0].value}</td>
                                )
                            }
                            {
                                race.errortext &&
                                (
                                    <td className='meetingstats__table__item' colSpan={8} key={'race' + ir + "_" + 1} style={{'background': `var(--state-alarm)`}}>{race.errortext.replace('Error:', '')}</td>
                                )
                            }
                            </tr>)
                    })
                }
                </tbody>
            </table>
        </div>
    );
}
