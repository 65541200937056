import { FC, ReactNode } from 'react';
import './footer.scss';

export const Footer : FC<{children?: ReactNode, text?: string}> = ({text = ''}) =>
{
    return (
        <div className='footer'>
            <div>{text}</div>
        </div>
    );
}
