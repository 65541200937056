/* -------------------------------------------------------------------------------- *\
|                               STATETYPES
\* -------------------------------------------------------------------------------- */
export enum STATETYPES
{
    STATETYPE_TEXT = "text",
    STATETYPE_BOOL = "bool",
    STATETYPE_GAUGE = "gauge",
    STATETYPE_LIVEINFO = "live",
    STATETYPE_HTTP = "http",
}
/* -------------------------------------------------------------------------------- *\
|                               CompetitorInfos
\* -------------------------------------------------------------------------------- */
export class CompetitorInfos
{
    public number: number = 0;
    public status: string = "";
    public qmods: number[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               RacingInfos
\* -------------------------------------------------------------------------------- */
export class RacingInfos
{
    public sic: string = "";
    public date: string = "";
    public norace: number = 0;
    public dps: number = 0;
    public trackers: number = 0;
    public distance: number = 0;
    public position: number = 0;
    public hsgpscount: number = 0;
    public hsracecount: number = 0;
    public started: boolean = false;
    public competitors: CompetitorInfos[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               ApiLiveInfos
\* -------------------------------------------------------------------------------- */
class ApiLiveInfos
{
    public connected: boolean = false;
    public lastdata: string = "";
    public datapersecond: number = 0;
    public lastdatastate: string = "";
    public racinginfos: RacingInfos | null = null;
}
/* -------------------------------------------------------------------------------- *\
|                               
\* -------------------------------------------------------------------------------- */
class ApiHttpInfo
{
    public good: boolean = false;
    public lastdata: string = "";
    public lastdatastate: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               ApiState
\* -------------------------------------------------------------------------------- */
export class ApiState
{
    public name: string = "";
    public probe: string = "";
    public type: STATETYPES = STATETYPES.STATETYPE_TEXT;
    public value: string = "";
    public numeric?: number;
    public color?: string;
    public valuemax?: string;
    public valuecurrent?: string;
    public liveinfos?: ApiLiveInfos;
    public httpinfos?: ApiHttpInfo;
}
/* -------------------------------------------------------------------------------- *\
|                               ApiServer
\* -------------------------------------------------------------------------------- */
export class ApiServer
{
    public name: string = "";
    public ip: string = "";
    public states: ApiState[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               ApiGroup
\* -------------------------------------------------------------------------------- */
export class ApiGroup
{
    public name: string = "";
    public servers: ApiServer[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               ApiGlobal
\* -------------------------------------------------------------------------------- */
export class ApiGlobal
{
    public groups: ApiGroup[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               ApiLives
\* -------------------------------------------------------------------------------- */
export class ApiLives
{
    public lives: RacingInfos[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               MecFile
\* -------------------------------------------------------------------------------- */
export class MecFile
{
    public sic: string = "";
    public race: number = 0;
    
    public datas: boolean = false; // 100% des datas
    public deltas: boolean = false; // écarts relatifs entre les chevaux
    public time: boolean = false; // temps tracking pas plus de 5s par rapport au temps officiel
    public mecfull: boolean = false;
    public mecsectional: boolean = false;
}
/* -------------------------------------------------------------------------------- *\
|                               Race
\* -------------------------------------------------------------------------------- */
export class Race
{
    public Number: number = 0;
    public Distance: number = 0;
    public StartTime: string = "";
    public Name: string = "";
    public Path: string = "";
    public State: string = "";
    public Runners: number = 0;
    public Nonrunners: number[] = [];
    public mec: MecFile | null = null;

    /* -------------------------------------------------------------------------------- *\
    |                               getstarttime
    \* -------------------------------------------------------------------------------- */
    public static getstarttime(race: Race, timenow: number): number
    {
        var ret: number = 0;

        try
        {
            const els: string[] = race.StartTime.split(':');
            if (els.length === 2)
            {
                const time: number = Number(els[0]) * 60 + Number(els[1]);
                if (time > timenow) ret = time;
            }
        }
        catch(error)
        {
        }
        return ret;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               RaceTime
\* -------------------------------------------------------------------------------- */
export class RaceTime
{
    public Time: number = 0;
    public Number: number = 0;
    public Sic: string = "";
    public Track: string = "";
    public StartTime: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               Meeting
\* -------------------------------------------------------------------------------- */
export class Meeting
{
    public Date: string = "";
    public sic: string = "";
    public Track: string = "";
    public Number: number = 0;
    public races: Race[] = [];

    /* -------------------------------------------------------------------------------- *\
    |                               getnearestrace
    \* -------------------------------------------------------------------------------- */
    public static getnearestrace(meeting: Meeting, timenow: number): RaceTime | null
    {
        var ret: RaceTime | null = null;

        for(const race of meeting.races)
        {
            const time = Race.getstarttime(race, timenow);
            if (time > 0)
            {
                if (!ret)
                {
                    ret = {Time: time, Sic: meeting.sic, Track: meeting.Track, StartTime: race.StartTime, Number: race.Number};
                }
                else if (time < ret.Time)
                {
                    ret.Time = time;
                    ret.StartTime = race.StartTime;
                    ret.Track = meeting.Track;
                    ret.Number = race.Number;
                }
            }
        }

        return ret;
    }
}
/* -------------------------------------------------------------------------------- *\
|                               ApiMeetings
\* -------------------------------------------------------------------------------- */
export class ApiMeetings
{
    public date: string = "";
    public version: number = 0;
    public meetings: Meeting[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               CompetitorStats
\* -------------------------------------------------------------------------------- */
export class ApiCompetitorStats
{
    public number: number = 0;
    public state: string = '';
    public percent10hz: number = 0;
    public percentPosC: number = 0;
    public latency: number = 0;
    public difftime: number = 0;
    public precision: number = 0;
    public valid: boolean = false;
}
/* -------------------------------------------------------------------------------- *\
|                               RaceStats
\* -------------------------------------------------------------------------------- */
export class ApiRaceStats
{
    public context: string = "";
    public runnercount: number = 0;
    public percent10hz: number = 0;
    public percentPosC: number = 0;
    public latency: number = 0;
    public invalidcount: number = 0;
    public valid: boolean = false;
    public difftime: number = 0;
    public competitorsfull: ApiCompetitorStats[] = [];
    public competitorsmec: ApiCompetitorStats[] = [];
    public errortext: string = "";
    public infolines: string[] = [];
    public server: number = 0;
    public operator: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               ApiStatsRaceStats
\* -------------------------------------------------------------------------------- */
export class ApiStatsRaceStats
{
    public racecount: number = 0;
    public validcount: number = 0;
    public meccount: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               ApiAnswerRaceStats
\* -------------------------------------------------------------------------------- */
export class ApiAnswerRaceStats
{
    public stats: ApiStatsRaceStats = new ApiStatsRaceStats();
    public races: ApiRaceStats[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               ApiRacingRace
\* -------------------------------------------------------------------------------- */
export class ApiRacingRace
{
    public context: string = "";
    public mecfull: boolean = false;
    public mecsectional: boolean = false;
    public server: string = "";
    public httpracing: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               ApiOperator
\* -------------------------------------------------------------------------------- */
export class ApiOperator
{
    public id: number = 0;
    public name: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               ApiRacingServer
\* -------------------------------------------------------------------------------- */
export class ApiRacingServer
{
    public id: number = 0;
    public name: string = "";
    public url: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               ApiTrack
\* -------------------------------------------------------------------------------- */
export class ApiTrack
{
    public id: number = 0;
    public sic: string = "";
    public name: string = "";
}
