import { FC } from 'react';
import { ApiServer } from '../../types';
import { ServerView } from './server';
import './servers.scss';

/* -------------------------------------------------------------------------------- *\
|                               ServersProps
\* -------------------------------------------------------------------------------- */
interface ServersProps
{
	list: ApiServer[],
	fullview: boolean,
	children?: Node
}
  /* -------------------------------------------------------------------------------- *\
|                               ServersView
\* -------------------------------------------------------------------------------- */
export const ServersView : FC<ServersProps> = ({list, fullview}) =>
{
    return (
        <div className='servers'>
            {
                list.map((apiserver, index) =>
                {
                    return <ServerView key={'server_' + index} apiserver={apiserver} fullview={fullview}></ServerView>
                })
            }
        </div>
    );
}
