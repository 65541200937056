import { FC } from 'react';
import { ApiState, STATETYPES } from '../../types';
import { GaugeView } from './gauge';
import './states.scss';

/* -------------------------------------------------------------------------------- *\
|                               StatesProps
\* -------------------------------------------------------------------------------- */
interface StatesProps
{
	list: ApiState[],
	fullview: boolean,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               StatesView
\* -------------------------------------------------------------------------------- */
export const StatesView : FC<StatesProps> = ({list, fullview}) =>
{
    const gauges: ApiState[] = list.filter(n => n.type === STATETYPES.STATETYPE_GAUGE);
    const texts: ApiState[] = list.filter(n => n.type === STATETYPES.STATETYPE_TEXT);
    const bools: ApiState[] = list.filter(n => n.type === STATETYPES.STATETYPE_BOOL);
    const lives: ApiState[] = list.filter(n => n.type === STATETYPES.STATETYPE_LIVEINFO);
    const https: ApiState[] = list.filter(n => n.type === STATETYPES.STATETYPE_HTTP);

    const linkhpv2: ApiState | undefined = list.find(n => n.probe === 'linkhpv2');
    if (linkhpv2 && fullview) texts.push(linkhpv2);

    const uptime: ApiState | undefined = list.find(n => n.probe === 'uptime');
    var showgauges: boolean = false;
    for(const g of gauges)
    {
        if (g.color !== "good")
        {
            showgauges = true;
            break;
        }
    }

    const global: string = uptime && uptime.value ? 'uptime ' + uptime.value : 'server not responding';
    const globalcolor: string = uptime && uptime.value ? 'good' : 'alarm';
    return (
        <div className='states'>
            {
                (fullview || !(uptime && uptime.value)) &&
                (
                    <div className='states__global' style={{'background': `var(--state-${globalcolor})`}}>
                        <div>{global}</div>
                    </div>
                )
            }
            <div className='states__gauges'>
            {
                gauges.map((state, index) =>
                {
                    return (
                        <div className='states__gauges__cont' key={'gauge_' + index}>
                            <GaugeView key={'gauge_' + index} state={state} fullview={fullview || showgauges}/>
                        </div>
                    )
                })
            }
            </div>
            <div className='states__lives'>
            {
                lives.map((state, index) =>
                {
                    const connected: string = state.liveinfos?.connected ? 'good' : 'alarm';
                    const lastdata: string = state.liveinfos ? state.liveinfos?.lastdatastate : 'alarm';
                    const dps: string = (state.liveinfos && state.liveinfos?.datapersecond > 5) ? (state.liveinfos && state.liveinfos?.datapersecond < 15 ? 'good' : 'alert') : '';
                    return (
                        <div className='states__lives__cont' key={'lives_' + index}>
                            <div className='states__lives__cont__cont'>
                                { state.liveinfos && state.liveinfos?.datapersecond > 2 ? (<div className='states__lives__cont__signal'></div>) : (<div></div>) }
                                {
                                    !state.liveinfos?.connected &&
                                    (
                                        <div className='states__lives__line'>
                                            <div className='states__lives__line__text'>racing {state.liveinfos?.connected ? "connected" : "not connected"}</div>
                                            <div className='states__lives__line__led' style={{'background': `var(--state-${connected})`}}/>
                                        </div>
                                    )
                                }
                                {
                                    state.liveinfos?.connected && state.liveinfos && state.liveinfos?.datapersecond <= 5 &&
                                    (
                                        <div className='states__lives__line'>
                                            <div className='states__lives__line__text'>racing last ping {state.liveinfos?.lastdata}</div>
                                            <div className='states__lives__line__led' style={{'background': `var(--state-${lastdata})`}}/>
                                        </div>
                                    )
                                }
                                {
                                    state.liveinfos?.connected && state.liveinfos && state.liveinfos?.datapersecond > 5 &&
                                    (
                                        <div className='states__lives__line'>
                                            <div className='states__lives__line__text'>racing data per second {state.liveinfos?.datapersecond}</div>
                                            <div className='states__lives__line__led' style={{'background': `var(--state-${dps})`}}/>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ) 
                })
            }
            </div>
            <div className='states__others'>
            {
                texts.map((state, index) =>
                {
                    return (
                        <div key={'texts_' + index}>
                        {
                            (fullview || state.color !== 'good') &&
                            (
                                <div className='states__lives__cont'>
                                    <div className='states__others__line'>
                                        <div className='states__others__line__text'>{state.name + " " + state.value}</div>
                                        <div className='states__others__line__led' style={{'background': `var(--state-${state.color})`}}/>
                                    </div>
                                </div>
                            )
                        }
                        </div>
                    )
                })
            }
            </div>
            <div className='states__others'>
            {
                bools.map((state, index) =>
                {
                    return (
                        <div key={'bool_' + index}>
                        {
                            (fullview || state.color !== 'good') &&
                            (
                                <div className='states__lives__cont'>
                                    <div className='states__others__line'>
                                        <div className='states__others__line__text'>{state.name}</div>
                                        <div className='states__others__line__led' style={{'background': `var(--state-${state.color})`}}>{state.value}</div>
                                    </div>
                                </div>
                            )
                        }
                        </div>
                    ) 
                })
            }
            </div>
            <div className='states__others'>
            {
                https.map((state, index) =>
                {
                    return (
                        <div key={'other_' + index}>
                        {
                            (fullview || state.color !== 'good') &&
                            (
                                <div className='states__lives__cont'>
                                    <div className='states__others__line'>
                                        <a className='states__others__line__text' rel="noreferrer" href={state.name} target='_blank'>{state.name}</a>
                                        <div className='states__others__line__led' style={{'background': `var(--state-${state.color})`}}>{state.value}</div>
                                    </div>
                                </div>
                            )
                        }
                        </div>
                    ) 
                })
            }
            </div>
        </div>
    );
}
